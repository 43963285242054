










import Vue from 'vue'
import Header from '~/components/commons/organisms/header/header.vue'
import Footer from '~/components/commons/organisms/footer/footer.vue'

export default Vue.extend({
  components: { Header, Footer },
  data(): {
    isOpen: boolean
  } {
    return {
      isOpen: false
    }
  },
  computed: {
    isHomePage(): boolean {
      return this.$route.path === '/'
    },
    isMobile(): boolean {
      return !!this.$route.query.mobile
    }
  },
  created() {
    // this.isOpen = true
  }
})

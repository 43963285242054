import { isWithinInterval } from 'date-fns'
export default function({ redirect }) {
  const isWithinInter = isWithinInterval(
    new Date(), // Now
    {
      start: new Date('2025-03-01 00:00:00 GMT+07:00'.replace(/-/g, '/')),
      end: new Date('2025-05-31 23:59:59 GMT+07:00'.replace(/-/g, '/'))
    }
  )
  if (!isWithinInter) {
    return redirect('/not-found')
  }
}

import { isWithinInterval } from 'date-fns'
import MGM25q2 from '~/pages/promotions/configs-promotions/member-get-member-2025-q2.json'
import MGM25 from '~/pages/promotions/configs-promotions/member-get-member-2025.json'

export default (app, redirect) => {
  if (
    app.$features('DWB-1828-EXTEND-MEMBER-GET-MEMBET-2025-Q2-F') &&
    isWithinInterval(new Date(), {
      start: new Date(
        `${MGM25q2.publishUp} 00:00:00 GMT+07:00`.replace(/-/g, '/')
      ),
      end: new Date(
        `${MGM25q2.publishDown} 23:59:59 GMT+07:00`.replace(/-/g, '/')
      )
    })
  ) {
    // correct, do nothing
  } else if (
    app.$features('DWB-1649-EXTEND-MEMBER-GET-MEMBET-2025-F') &&
    isWithinInterval(new Date(), {
      start: new Date(
        `${MGM25.publishUp} 00:00:00 GMT+07:00`.replace(/-/g, '/')
      ),
      end: new Date(
        `${MGM25.publishDown} 23:59:59 GMT+07:00`.replace(/-/g, '/')
      )
    })
  ) {
    // correct, do nothing
  } else {
    return redirect('/not-found')
  }
}
